import { render, staticRenderFns } from "./Orsigntoshare.vue?vue&type=template&id=5cdf4aee&scoped=true&"
import script from "./Orsigntoshare.vue?vue&type=script&lang=js&"
export * from "./Orsigntoshare.vue?vue&type=script&lang=js&"
import style0 from "./Orsigntoshare.vue?vue&type=style&index=0&id=5cdf4aee&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cdf4aee",
  null
  
)

export default component.exports